import { Box, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { UserAvatar } from './UserAvatar';

export const LeaderboardItem = ({
    isCurrentUser = false,
    user = {},
    rank = 0,
    isAdmin = false,
    showHeader = false,
}) => {
    const { classes } = useStyles();
    return (
        <Box
            className={`${classes.itemContainer} ${
                isCurrentUser ? classes.currentUser : ''
            }`}
        >
            <Box display={'flex'} alignItems="center" flexGrow={1}>
                <Box className={`${classes.item} ${classes.rankItem}`}>
                    <Typography className={classes.rank}>
                        {showHeader ? 'Rank' : rank}
                    </Typography>
                </Box>
                <Box className={`${classes.item}`}>
                    {showHeader ? (
                        <></>
                    ) : (
                        <UserAvatar
                            user={user}
                            size={40}
                            name={`${user.user_firstName} ${user.user_lastName}`}
                        />
                    )}
                </Box>
                <Typography className={`${classes.item} ${classes.name}`}>
                    {showHeader
                        ? isAdmin
                            ? 'Name'
                            : 'Screen Name'
                        : isAdmin
                        ? `${user.user_firstName} ${user.user_lastName}`
                        : `${user.display_name}`}
                </Typography>
            </Box>
            <Box pr={2}>
                <Typography className={classes.points}>
                    {showHeader ? (
                        'Stardust'
                    ) : (
                        <>{user.stardust_points.toLocaleString()} Points</>
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

const CARD_HEIGHT = 40;

const useStyles = makeStyles()((theme) => ({
    itemContainer: {
        borderRadius: '0px 2px 2px 0px',
        backgroundColor: '#f3f3f3',
        margin: '1px 0',
        display: 'flex',
        minHeight: CARD_HEIGHT,
        padding: '10px 10px 10px 0',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: theme.shadows[2],
        overflow: 'hidden',
    },
    currentUser: {
        backgroundColor: 'rgb(55 165 200 / 38%)',
        [theme.breakpoints.up('md')]: {
            transform: 'scale(1.05)',
            marginInline: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.up('lg')]: {
            transform: 'scale(1.05)',
            marginInline: theme.spacing(3),
        },
    },
    rankItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: CARD_HEIGHT + 20,
        height: CARD_HEIGHT,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: CARD_HEIGHT,
            minWidth: CARD_HEIGHT,
            height: CARD_HEIGHT,
        },
        zIndex: '1',
        '&::before': {
            position: 'absolute',
            content: '""',
            backgroundColor: '#333',
            top: '-25%',
            left: '0',
            height: '150%',
            width: '100%',
            zIndex: '-1',
        },
    },
    rank: {
        color: '#fafafa',
        fontSize: 'min(1.5em, 4vw)',
    },
    name: {
        fontSize: 'min(1.5em, 4vw)',
        color: '#3e3e3e',
        fontWeight: '500',
        minWidth: '250px',
        [theme.breakpoints.down('md')]: {
            minWidth: 'fit-content',
            width: 'fit-content',
        },
    },
    points: {
        fontSize: 'min(1.5em, 3.5vw)',
        color: theme.palette.common.lightBlue,
        fontWeight: '600',
    },
    item: {
        marginRight: '16px',
    },
}));
