import { Box, Container } from '../ui';
import { Link, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ChevronRight } from '@mui/icons-material';
import { HelmetWrapper } from '../seo';

const useStyles = makeStyles()((theme) => ({
    container: {
        paddingBottom: theme.spacing(6),
    },
    breadCrumbs: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'uppercase',
        color: '#475569',
        flexWrap: 'wrap',
        '& > .crumb': {
            flexShrink: 0,
        },
        '& > .crumb:hover': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '90px',
        },
    },
}));

export const AdminContainer = ({
    children,
    hideBtn = false,
    userDefinedCrumb = undefined,
    replaceWith = undefined,
    hideHemelWrapper = false,
    userDefinedTitle = '',
    disableGutters = false,
}) => {
    const location = useLocation();
    const { classes } = useStyles();

    const crumbs = useMemo(() => {
        let currentLink = '';

        const crumbs = location.pathname
            .split('/')
            .filter((crumb) => crumb !== '')
            .map((crumb, idx, arr) => {
                currentLink += `/${crumb}`;

                let _crumb = '';

                if (userDefinedCrumb && replaceWith && crumb === replaceWith) {
                    _crumb = userDefinedCrumb;
                } else {
                    _crumb = crumb;
                }

                return (
                    <React.Fragment key={crumb + '-' + idx}>
                        <div className="crumb">
                            <Link to={currentLink}>{_crumb}</Link>
                        </div>
                        {arr.length - 1 !== idx && <ChevronRight />}
                    </React.Fragment>
                );
            });

        return crumbs;
    }, [location.pathname, replaceWith, userDefinedCrumb]);

    const title = useMemo(() => {
        if (userDefinedTitle) return userDefinedTitle;
        const splittedPathName = location.pathname.split('/');
        const lastItem = splittedPathName.pop();
        const sanitizedStr = lastItem.replace(/[^a-zA-Z0-9]/gi, ' ');
        let sanitizedStrArr = sanitizedStr.split(' ');

        for (let i = 0; i < sanitizedStrArr.length; i++) {
            let currStr = sanitizedStrArr[i];
            const strLength = currStr.length;
            currStr =
                currStr.charAt(0).toUpperCase() + currStr.slice(1, strLength);
            sanitizedStrArr[i] = currStr;
        }

        return sanitizedStrArr.join(' ');
    }, [location.pathname, userDefinedTitle]);

    return (
        <Container
            maxWidth="xl"
            className={classes.container}
            disableGutters={disableGutters}
        >
            {!hideHemelWrapper && <HelmetWrapper title={title} />}
            {hideBtn === false && (
                <div className={classes.breadCrumbs}>{crumbs}</div>
            )}
            <Box pt={4}>{children}</Box>
        </Container>
    );
};
