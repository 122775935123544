import React, { useEffect, useMemo, useState } from 'react';

import { Anchor, Box, Grid, Divider, Typography } from '..';

import { makeStyles } from 'tss-react/mui';

import { useCurrentUser } from '../../my-account/useCurrentUser';

import axios from 'axios';
import { Tooltip } from '@mui/material';
import { useRecoilState } from 'recoil';
import { roleViewState } from '../../navigation/recoil/roleView';

const useStyles = makeStyles()((theme) => ({
    linksContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        width: '100%',
    },

    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 300,
    },

    iconContainer: {
        marginBottom: 10,
    },
    link: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        textDecoration: 'underline',
        marginBottom: 10,
    },

    info: {
        textAlign: 'center',
        marginBottom: 40,
    },
}));

export const HomeLinks = ({ links }) => {
    const { classes } = useStyles();
    const { user: userDetails } = useCurrentUser();
    const [isComplete, setIsComplete] = useState(true);
    const [loading, setLoading] = useState(false);
    const [roleView] = useRecoilState(roleViewState);

    const mid = useMemo(() => {
        return roleView.selectedView === 'admin' ? 3 : 4;
    }, [roleView.selectedView]);

    // useEffect(() => {
    //     let willUnmount = false;

    //     if (willUnmount && !userDetails) return;

    //     //* helper fn to check is the profile is completed or not
    //     const veifyAccountCompletion = (
    //         intro,
    //         topics,
    //         tagLine,
    //         profilePic,
    //         personas,
    //         blogs,
    //         paypalDetails
    //     ) => {
    //         if (
    //             !intro ||
    //             intro.length < 150 ||
    //             !topics ||
    //             topics.length < 10 ||
    //             !tagLine ||
    //             tagLine.length === 0 ||
    //             !profilePic ||
    //             !personas ||
    //             personas < 6 ||
    //             !blogs ||
    //             blogs < 6 ||
    //             !paypalDetails
    //         )
    //             return false;

    //         return true;
    //     };

    //     (async function () {
    //         try {
    //             setLoading(true);
    //             const res = await axios.get(
    //                 `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor/${userDetails?.user_email}?status=any&nowprocket=1`
    //             );

    //             // console.log(res.data);
    //             const intro = res.data?.bio;
    //             const topics = userDetails?.instructor_details?.topics;
    //             const tagLine = res.data?.tagLine;
    //             const profilePic = res.data?.profilePic;
    //             const personas = res.data?.relatedJobTitles?.length;
    //             const blogs = res.data?.relatedArticles?.length;
    //             const paypalDetails = userDetails?.paypal_details;

    //             if (
    //                 veifyAccountCompletion(
    //                     intro,
    //                     topics,
    //                     tagLine,
    //                     profilePic,
    //                     personas,
    //                     blogs,
    //                     paypalDetails
    //                 ) === false
    //             ) {
    //                 setIsComplete(false);
    //                 setLoading(false);
    //             } else {
    //                 setIsComplete(true);
    //                 setLoading(true);
    //             }

    //             setLoading(false);
    //         } catch (e) {
    //             console.log(e);
    //             setLoading(false);
    //         }
    //     })();

    //     return () => {
    //         willUnmount = true;
    //     };
    // }, [userDetails]);

    //handler fn to check if the profile is completed or not
    const handleProfileComplete = (currText) => {
        if (
            (currText === 'My Channel' ||
                currText === 'Edit my Profile' ||
                currText === 'Configure My Channel') &&
            isComplete === false
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Box className={classes.linksContainer}>
                {links
                    .slice(0, mid)
                    .map(({ icon, text, info, link, isExternal, isNewTab }) => (
                        <Box className={classes.linkContainer} key={link}>
                            <Box className={classes.iconContainer}>{icon}</Box>

                            <Anchor
                                to={
                                    (text === 'My Channel' ||
                                        text === 'Edit my Profile' ||
                                        text === 'Configure My Channel') &&
                                    isComplete === false
                                        ? '/profile/instructor-dashboard/channel-configuration/about-me'
                                        : link
                                }
                                isExternal={isExternal}
                                isNewTab={isNewTab}
                                loading={loading}
                            >
                                <Tooltip
                                    title={
                                        handleProfileComplete(text)
                                            ? 'Your profile is incomplete. To launch your channel, please complete the remaining items.'
                                            : ''
                                    }
                                    arrow
                                >
                                    <Typography
                                        className={classes.link}
                                        // style={
                                        //     (text === 'My Channel' ||
                                        //         text === 'Edit my Profile') &&
                                        //     isComplete === false
                                        //         ? { color: 'red' }
                                        //         : { color: '' }
                                        // }
                                        style={
                                            handleProfileComplete(text)
                                                ? { color: 'red' }
                                                : { color: '' }
                                        }
                                    >
                                        {text}
                                    </Typography>
                                </Tooltip>
                            </Anchor>

                            <Typography
                                variant="body2"
                                className={classes.info}
                            >
                                {info}
                            </Typography>
                        </Box>
                    ))}
            </Box>

            <Box className={classes.linksContainer}>
                {links
                    .slice(mid, links.length)
                    .map(({ icon, text, info, link, isExternal, isNewTab }) => (
                        <Box className={classes.linkContainer} key={link}>
                            <Box className={classes.iconContainer}>{icon}</Box>

                            <Anchor
                                to={
                                    (text === 'My Channel' ||
                                        text === 'Edit my Profile' ||
                                        text === 'Configure My Channel') &&
                                    isComplete === false
                                        ? '/profile/instructor-dashboard/channel-configuration/about-me'
                                        : link
                                }
                                isExternal={isExternal}
                                isNewTab={isNewTab}
                                loading={loading}
                            >
                                <Tooltip
                                    title={
                                        handleProfileComplete(text)
                                            ? 'Your profile is incomplete. To launch your channel, please complete the remaining items.'
                                            : ''
                                    }
                                    arrow
                                >
                                    <Typography
                                        className={classes.link}
                                        // style={
                                        //     (text === 'My Channel' ||
                                        //         text === 'Edit my Profile') &&
                                        //     isComplete === false
                                        //         ? { color: 'red' }
                                        //         : { color: '' }
                                        // }
                                        style={
                                            handleProfileComplete(text)
                                                ? { color: 'red' }
                                                : { color: '' }
                                        }
                                    >
                                        {text}
                                    </Typography>
                                </Tooltip>
                            </Anchor>

                            <Typography
                                variant="body2"
                                className={classes.info}
                            >
                                {info}
                            </Typography>
                        </Box>
                    ))}
            </Box>
        </>
    );
};
