const roleList = [
    {
        _id: '6606e47e9e1df6db2dd50caf',
        label: 'Producers/Engagement Managers',
        field: 'SMEengagements',
    },
    {
        _id: '6606e4729e1df6db2dd50cae',
        label: 'Delivery Managers & QA',
        field: 'administrators',
    },
    {
        _id: '6606e48b9e1df6db2dd50cb0',
        label: 'Instructional Designers',
        field: 'instructionalDesigners',
    },
    {
        _id: '6606e4939e1df6db2dd50cb1',
        label: 'Editor & Animators',
        field: 'videoEditorsReviewers',
    },
    {
        _id: '6606e4a09e1df6db2dd50cb2',
        label: 'Logistics/Operations Team',
        field: 'logisticsOperations',
    },
    {
        _id: '67516f0d2ff785245b2ef951',
        label: 'Finance & Administration',
        field: 'financeAdministration',
    },
    {
        _id: '67615c7067dad47f0578fc0b',
        label: 'Talent Scout',
        field: 'talentScout',
    },
    {
        _id: '67615c7867dad47f0578fc0c',
        label: 'Video Producer',
        field: 'videoProducer',
    },
    {
        _id: '67615c8067dad47f0578fc0d',
        label: 'Quality Check',
        field: 'qualityCheck',
    },
];

const roleToHeader = {};

for (const role of roleList) {
    roleToHeader[role.field] = role.label;
}

export { roleToHeader, roleList };
